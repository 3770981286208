.App {
  height: auto;
  margin: 0;
  background-color: #000000;
  /* overflow: hidden; */
  overflow-y: auto;
  text-rendering:optimizeLegibility;
  font-weight: 400

}
html {
  scroll-behavior: smooth;
}

.loading-screen {
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  height: 100vh;
  width: 100vw;
  background-color: #000000;
  color: #FFFFFF;
  font-family: 'Regular';
  font-size: 24px;
}
.loading-screen pre {
  font-size: 1.2rem; /* Base font size */
  white-space: pre-wrap; /* Wrap text on small screens */
}

@media (max-width: 600px) { /* For screens smaller than 600px */
  .loading-screen pre {
    font-size: 1rem; /* Smaller font size */
  }

  .loading-screen {
    padding: 10px; /* Less padding on smaller screens */
  }
}

@media (max-width: 400px) { /* For very small screens */
  .loading-screen pre {
    font-size: 0.9rem; /* Even smaller font size */
  }
}


/* Add this to your App.css */

.typing-effect {
  overflow: hidden;
  border-right: 3px solid;
  display: inline-block;
  width: 0;
  animation: typing 5s steps(100, end), blink 0.75s step-end infinite;
  white-space: pre-wrap; /* Preserve line breaks */
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}


.washi-tape {
  width: 100%;
  height: 40px; /* Adjust height for your tape size */
  background-color: #BAFF00; /* Tape background color */
  display: flex;
  align-items: center;
  overflow: hidden;
  position: absolute;
  top: 0; /* Positioning at the top of the screen */
}

.under-washi-tape {
  color: #FFFFFF; /* Text color */
  font-family: 'Regular';
  margin: 0; /* Reset margin to avoid spacing issues */
  font-size: 40px;
  line-height: 1; /* Adjust line height for readability */
  flex: 2.5; /* Allow it to take available space */
  padding: 10px; /* Add padding for better spacing */
}

.scrolling-text {
  display: flex; /* Allow text to sit in a row */
  white-space: nowrap;
  font-size: 20px; /* Adjust the size of the text */
  padding-left: 100%; /* Start outside the view */
  font-family: 'UltraBold';
  animation: scrollText 25s linear infinite;
  color: #2B292B; /* Text color */
  font-weight: bold;
}

.video-container {
  flex: 1; /* Allow the video to take up available space */
  display: flex;
  justify-content: right;
  align-items: flex-start; /* Align video to the top */
  padding: 40px; 
  position: relative;

  /* Add padding to keep it aligned with the text */
}

/* Tape overlay at the bottom left */
.tape-overlay {
  position: absolute;
  bottom: -20px; /* Adjust bottom offset to fine-tune */
  left: 14vh; /* Adjust left offset to fine-tune */
  transform: rotate(-5deg); /* Optional: rotated effect */
  z-index: 2; /* Ensure it stays above the video */
}

.tape-overlay img {
  width: 150px; /* Adjust size of the tape */
  height: auto;
  opacity: 0.8;
}

/* Tape overlay at the top right */
.tape-overlay-top {
  position: absolute;
  top: -50px; /* Adjust as needed */
  right: 20px; /* Adjust right offset */
  transform: rotate(-105deg); /* Optional: rotated effect */
  z-index: 2; /* Ensure it stays above the video */
}

.tape-overlay-top img {
  width: 150px; /* Adjust size of the tape */
  height: auto;
  opacity: 0.6;
}

.app-video {
  width: 100%;
  max-width: 330px; /* Adjust according to your video dimensions */
  height: auto;
  /* border-radius: 30px; */
}

.content-row {
  display: flex;  /* Use flexbox to align text and video in the same row */
  justify-content: space-between;  /* Space items to edges */
  align-items: flex-start;  /* Align both items at the top */
  margin: 20px;
}

/* Dock styling */
.dock {
  position: fixed;
  bottom: 20px; /* Adjust this value to add space between the dock and the bottom */
  left: 50%;
  transform: translateX(-50%); /* Center the dock horizontally */
  width: auto; /* Let the width be determined by its content */
  padding: 10px 30px; /* Adjust padding for the dock */
  background-color: rgba(43, 41, 43, 0.4); /* Semi-transparent background for glass effect */
  display: flex;
  justify-content: center; /* Center the dock items */
  align-items: center;
  border-radius: 20px; /* Rounded edges for the dock */
  z-index: 1000; /* Keep it above everything else */

  /* Add white border and glow */
  border: 0.7px solid rgba(255, 255, 255, 0.3); /* Light border */
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.3); /* Slight white glow */

  /* Glass effect */
  backdrop-filter: blur(10px); /* Adjust the blur for the glass effect */
  -webkit-backdrop-filter: blur(10px); /* For Safari support */
}


.dock-item {
  width: 60px;
  height: 60px;
  max-width: 40px;
  max-height: 40px;
  margin: 0 15px;
  border-radius: 10px;
  transition: transform 0.2s ease-in-out; /* Hover effect for zoom */
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'UltraBold'; 
  color: black;
  cursor: pointer;
}
.dock-item img {
  width: 100%; /* This will make the image scale according to the container's size */
  height: 100%;
}


.dock-item:hover {
  transform: scale(1.3); /* Zoom effect on hover */
}

.location-address {
  display: flex; /* Keep using flex to align items horizontally */
  align-items: center; /* Vertically center the image and text */
  
  color: #FFFFFF; /* Text color */
  font-family: 'Regular';
  font-size: 20px; /* Adjust text size */
  margin-left: 40px;
  margin-top: -120px;
}

.location-address img {
  width: 20px; /* Adjust size of the image */
  height: auto;
  margin-right: 10px; /* Add spacing between image and text */
  margin-bottom: 5px;
  

}
/* Media query for mobile screens */
@media (max-width: 768px) { /* Adjust max-width as needed for your design */
  .location-address {
    margin-top: 20px; /* Adds space between the video and the text */
  }

}

/* Styling for the new square grid */
.square-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 equal-width columns */
  grid-gap: 12px; /* 12px gap between the squares */
  width: 100%; /* Full width */
  filter: blur(20px); /* Initial blur */
  transform: scale(0.8); /* Initial smaller scale */
  transition: filter 0.5s ease, transform 0.5s ease; /* Smooth transition for the blur and scale effects */
  margin-top: 50vh; /* Start completely out of view (adjust as necessary) */
  padding: 0 12px; /* Optional: padding to prevent gap from content edges */
}


.square-grid.in-view {
  filter: blur(0px);
  transform: scale(1);
}


.square {
  position: relative;
  width: 100%;
  padding-top: 100%; /* Ensures the square maintains its aspect ratio */
  background-color: #ccc;
  border: 1px solid #000;
  overflow: hidden; /* Ensures no content spills outside the square */
  transition: transform 0.2s ease-in-out; /* Smooth transition for the movement */
}

.square:hover {
  transform:  scale(1.01);
}



.image-square {
  position: relative; /* Ensures the inner content respects the parent square's dimensions */
}

.square-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the entire square without distortion */
  object-position: center; /* Centers the image within the square */
}

/* This ensures content inside the square aligns correctly */
.square-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}


/* Responsive styles for mobile screens */
@media (max-width: 768px) {
  .square-grid {
    grid-template-columns: 1fr; /* Single column for mobile screens */
  }
}



@keyframes scrollText {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

@font-face {
  font-family: "UltraBold";   
  src: local("PPNeueMachina-PlainUltrabold"),
    url("./fonts/PPNeueMachina-PlainUltrabold.otf") format("opentype");
}

@font-face {
  font-family: "Regular";
  src: local("PPNeueMachina-InktrapRegular"),
    url("./fonts/PPNeueMachina-InktrapRegular.otf") format("opentype");
}


@media (max-width: 768px) {
  .square-grid{
    margin-top: 20vh; /* Adjust margin for smaller screens */
  }

  .dock {
    padding: 5px 20px; /* Smaller padding on mobile */
    border-radius: 15px; /* Slightly smaller border radius */
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.4); /* Adjust glow for mobile */
  }

  .dock-item {
    width: 45px; /* Reduce size of dock items */
    height: 45px;
    margin: 0 10px; /* Less space between items */
  }

  .under-washi-tape {
    font-size: 24px; /* Reduce text size for smaller screens */
    margin-top: 2%; /* Adjust margin for text positioning */
    margin-left: 2%; /* Adjust left margin for mobile */
  }

  .app-video {
    width: 80%; /* Reduce video size for smaller screens */
    max-width: 300px; /* Maximum width on mobile */
    height: auto;
    border-radius: 20px; /* Smaller border radius for video */
  }

  .content-row {
    flex-direction: column; /* Stack text and video vertically on mobile */
    align-items: center; /* Center items */
  }
}

/* Media query for very small screens */
@media (max-width: 480px) {
  .dock {
    padding: 20px 10vh;
  }

  .dock-item {
    width: 3vh;
    height: 3vh;
    margin: 0 15px; /* Less space between items */
  }

  .under-washi-tape {
    font-size: 20px; /* Further reduce text size for small screens */
  }

  .app-video {
    width: 90%; /* Maximize video size on very small screens */
    max-width: 250px;
  }
}

